import React, { useEffect } from "react";
import { Link } from "gatsby";
import { connect } from "react-redux";
import { trackingGA } from '@merchx-v2/helpers'
import { navigate } from 'utils/navigate'
import { Footer, Header, ProfileOrderItem, SEO } from "components";
import goBackIcon from "assets/goBack.svg";
// import prevPageIcon from "assets/previousPageIcon.svg";
// import nextPageIcon from "assets/nextPageIcon.svg";

import * as actions from "state/LoginPage/actions";
import { selectors } from "state/LoginPage/reducer";
import {
  actionCreators as actionHomeCreators,
  selectMenu,
} from "state/HomePage/reducer";
import * as styles from "./ProfileOrdersPage.module.scss";

const ProfileOrdersPage = ({
  loginState,
  menu,
  customerAccountOrdersPage,
  resetMenuFilters,
  setMenuCollection,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    trackingGA()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    customerAccountOrdersPage();
  }, [customerAccountOrdersPage]);

  const handleCollectionClicked = (collection) => {
    setMenuCollection(collection);
  };

  const handleReset = () => {
    resetMenuFilters();
  };

  if (typeof localStorage === 'undefined' || typeof sessionStorage === 'undefined') {
    return null
  }

  const refreshToken =
    localStorage.getItem("refreshToken") ||
    sessionStorage.getItem("refreshToken");

  if (!refreshToken) {
    return null;
  }

  return (
    <div className={styles.container}>
      <SEO pathname='/profile/orders' />
      <Header
        menu={menu}
        onCollectionClicked={handleCollectionClicked}
        onResetCollection={handleReset}
        isNeedDescription={true}
      />
      <div className={styles.content}>
        <div className={styles.title}>
          <img
            className={styles.goBackIcon}
            src={goBackIcon}
            alt="<"
            onClick={() => navigate('/profile')}
          />
          Orders
        </div>
        <div className={styles.subtitle}>
          Have questions about your order? See our{" "}
          <Link to="/faq" className={styles.link}>
            FAQ's
          </Link>
        </div>
        {/* <InputSearch placeholder="Search by order number" /> */}
        <div className={styles.titleSecond}>Past Orders</div>
        {loginState.orders &&
          loginState.orders.items &&
          loginState.orders.items.map((item) => {
            return <ProfileOrderItem order={item} />;
          })}

        {/* <div className={styles.paginationContainer}>
          <img className={styles.prevIcon} src={prevPageIcon} alt="&lsaquo;" />
          <div className={styles.pagesBox}>
            <div className={styles.page}>1</div>
            <div className={styles.pageActive}>2</div>
          </div>
          <img className={styles.nextIcon} src={nextPageIcon} alt="&rsaquo;" />
        </div> */}
      </div>
      <div className={styles.footerContainer}>
        <Footer handleReset={handleReset} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  menu: selectMenu(state),
  loginState: selectors.selectLoginState(state),
});

const mapDispatchToProps = {
  customerAccountOrdersPage: actions.customerAccountOrdersPage,
  resetMenuFilters: actionHomeCreators.resetMenuFilters,
  setMenuCollection: actionHomeCreators.setMenuCollection,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileOrdersPage);

// extracted by mini-css-extract-plugin
export var container = "ProfileOrdersPage-module--container--1zVNx";
export var content = "ProfileOrdersPage-module--content--3ALzw";
export var title = "ProfileOrdersPage-module--title--3tibd";
export var titleSecond = "ProfileOrdersPage-module--titleSecond--3-VVq";
export var goBackIcon = "ProfileOrdersPage-module--goBackIcon--3fiQ8";
export var subtitle = "ProfileOrdersPage-module--subtitle--iSumw";
export var link = "ProfileOrdersPage-module--link--1kPzP";
export var btnBlackContainer = "ProfileOrdersPage-module--btnBlackContainer--D9957";
export var blackButton = "ProfileOrdersPage-module--blackButton--v28tS";
export var paginationContainer = "ProfileOrdersPage-module--paginationContainer--2O7dM";
export var pagesBox = "ProfileOrdersPage-module--pagesBox--3muK0";
export var page = "ProfileOrdersPage-module--page--1NSO1";
export var pageActive = "ProfileOrdersPage-module--pageActive--cOuH5";
export var footerContainer = "ProfileOrdersPage-module--footerContainer--atlsp";